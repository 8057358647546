import { BlocksRenderer } from "@strapi/blocks-react-renderer"
import { GetStaticPaths } from "next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Link from "next/link"
import React from "react"
import Image from "next/image"
import { Icon } from "@iconify/react"

const GoogleMapIframe = React.lazy(() => import('../components/GoogleMapIFrame'))

export const customBlockRenderer = (content: any, translation?: any) => {
    const parseTextType = (child: any) => {
        let text = child.text
        if (text !== undefined) {
            if (text.toLowerCase().startsWith("price:")) {
                text = text.replace("price:", "")
            } else if (text.toLowerCase().startsWith("position:")) {
                text = text.replace("position:", "")
            } else if (text.toLowerCase().startsWith("how_to_get_there:")) {
                text = text.replace("how_to_get_there:", "")
            }
        }

        if (child.type  === "link") {
            return (
                <a href={child.url} target="_blank" rel="noreferrer">{child?.children?.[0]?.text}</a>
            )
        } else if (child.type === "text" && child.bold) {
            return (
                <strong>{text}</strong>
            )
        } else if (child.type === "text" && child.italic) {
            return (
                <em>{text}</em>
            )
        } else if (child.type === "text" && child.underline) {
            return (
                <u>{text}</u>
            )
        } else {
            return text
        }
    }

    return (
        <div className='prose max-w-none break-words text-md'>
            <BlocksRenderer
                content={content}
                blocks={{
                    paragraph: ({ children }) => <p className="w-full">{children}</p>,
                    link: ({ children, url }) => {
                        const parseLink = (url: string) => {
                            if (url.includes("http")) {
                                return (
                                    <a href={url} target="_blank" rel="noreferrer" aria-label={url}>{children}</a> 
                                )
                            } else {
                                if (url.includes("/cky-banner-element")) {
                                    return (
                                        <a className="cky-banner-element cursor-pointer" aria-label={url}>{children}</a>
                                    )
                                } else {
                                    return (
                                        <Link href={{pathname: url}} aria-label={url}>{children}</Link>
                                    )
                                }
                            }
                        }

                        return (
                            parseLink(url)
                        )
                    },
                    heading: ({ children, level }) => {
                        // Get the text content of the first child, if available
                        const id = children[0].props.text
                        switch (level) {
                            case 1:
                                return <h1 id={id}>{children}</h1>
                            case 2:
                                return <h2 id={id}>{children}</h2>
                            case 3:
                                return <h3 id={id}>{children}</h3>
                            case 4:
                                return <h4 id={id}>{children}</h4>
                            case 5:
                                return <h5 id={id}>{children}</h5>
                            case 6:
                                return <h6 id={id}>{children}</h6>
                            default:
                                return <p id={id}>{children}</p>
                        }
                    },
                    image: ({ image }) => {
                        const isPortrait = image?.height > image?.width

                        return (
                            <>
                                <div className="flex flex-col justify-center items-center -mb-[0.5rem] -mt-[1rem]">
                                    <Image
                                        src={image?.url}
                                        alt={image?.alternativeText || 'Image'}
                                        className="rounded-xl drop-shadow-2xl"
                                        style={{
                                            objectFit: 'cover',
                                            maxHeight: isPortrait ? '70vh' : '80%',
                                            width: 'auto',
                                            height: isPortrait ? 'auto' : '100%',
                                            maxWidth: '100%',
                                        }}
                                        width={image?.width}
                                        height={image?.height}
                                        loading="lazy"
                                    />
                                    <span className="text-xs text-center -mt-[1rem]">{image?.caption}</span>
                                </div>
                            </>
                        )
                    },
                    list: ({ children }) => {
                        let type = "standard"
                        let adv_link = ""
                        let adv_image = ""
                        let adv_title = ""
                        const children_array = []
                        const icons = {
                            "pros": <Icon icon="mdi:thumb-up-outline" />,
                            "cons": <Icon icon="mdi:thumb-down-outline" />,
                            "price": <Icon icon="mdi:money" />,
                            "position": <Icon icon="mdi:map-marker-outline" />,
                            "how_to_get_there": <Icon icon="mdi:walking" />,
                            "other": <Icon icon="mdi:plus" />
                        }
                        if (children[0].props.content.children?.[0]?.text === "pros-cons") {
                            type = "pros-cons"
                        } else if (children[0].props.content.children?.[0]?.text === "general-info") {
                            type = "general-info"
                            for (let i = 1; i < React.Children.count(children); i++) {
                                const elements = children?.[i]?.props?.content?.children
                                const row_parts = []
                                for (let j = 0; j < elements?.length; j++) {
                                    row_parts.push(elements?.[j])
                                }
                                children_array.push(row_parts)
                            }
                        } else if (children[0].props.content.children?.[0]?.text === "advice") {
                            type = "advice"
                            for (let i = 1; i < React.Children.count(children); i++) {
                                const elements = children?.[i]?.props?.content?.children
                                for (let j = 0; j < elements?.length; j++) {
                                    children_array.push(elements?.[j])
                                }
                            }
                        } else if (children[0].props.content.children?.[0]?.text === "map") {
                            type = "map"
                        } else if (children[0].props.content.children?.[0]?.text === "advertisement") {
                            type = "advertisement"
                            adv_link = children?.[1]?.props?.content?.children?.[0]?.text
                            adv_image = children?.[2]?.props?.content?.children?.[0]?.text
                            adv_title = children?.[3]?.props?.content?.children?.[0]?.text
                        }
                        
                        return (
                            <>
                                {type === "standard" ? (
                                    <ul className="marker:text-black">
                                        {children}
                                    </ul>
                                ) : (
                                    <>
                                        {type === "pros-cons" && (
                                            <div className="flex flex-col bg-gray-200 p-2 rounded-md mb-2 mt-2">
                                                <div className="flex flex-row items-center text-lg gap-1">
                                                    <span className="text-primary">
                                                        <Icon icon="mdi:chat-outline" />
                                                    </span>
                                                    <span className="underline">
                                                        {translation("post.considerations")}
                                                    </span>
                                                </div>
                                                <div className="ml-5">
                                                    <div className="flex flex-row justify-start items-start gap-2">
                                                        <span className="mt-[0.18cm]">{icons.pros}</span>
                                                        {children[1].props.content.children?.[0]?.text}
                                                    </div>
                                                    <div className="flex flex-row justify-start items-start gap-2">
                                                        <span className="mt-[0.18cm]">{icons.cons}</span>
                                                        {children[2].props.content.children?.[0]?.text}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {type === "general-info" && (
                                            <div className="flex flex-col bg-gray-200 p-2 rounded-md mb-2 mt-2">
                                                <div className="flex flex-row items-center text-lg gap-1">
                                                    <span className="text-primary">
                                                        <Icon icon="mdi:information-outline" />
                                                    </span>
                                                    <span className="underline">
                                                        {translation("post.general_info")}
                                                    </span>
                                                </div>
                                                <div className="flex flex-col gap-1">
                                                    {children_array && children_array.map((child: any, index_ext: number) => (
                                                        <div className="ml-5" key={`${child?.[0]?.props?.key}-advice-${index_ext}`}>
                                                            <div className="flex flex-row justify-start items-start gap-2">
                                                                <span className="mt-[0.18cm]">
                                                                    {child?.[0]?.text.split(":")[0].toLowerCase() === "price" && <span>{icons.price}</span>}
                                                                    {child?.[0]?.text.split(":")[0].toLowerCase() === "position" && <span>{icons.position}</span>}
                                                                    {child?.[0]?.text.split(":")[0].toLowerCase() === "how_to_get_there" && <span>{icons.how_to_get_there}</span>}
                                                                    {Object.prototype.hasOwnProperty.call(icons, child?.[0]?.text.split(":")[0].toLowerCase()) ? "" : <span>{icons.other}</span>}
                                                                </span>
                                                                <span>
                                                                    {child?.[0]?.text.split(":").length > 1 && (
                                                                        <>
                                                                            {(translation("post."+child?.[0]?.text.split(":")[0]).split(".")[0]) !== "post" ? (
                                                                                <>
                                                                                    {translation("post."+child?.[0]?.text.split(":")[0])}
                                                                                    {": "}
                                                                                    {child && child.map((child_element: any, index: number) => (
                                                                                        <span key={`${child?.[0]?.props?.key}-advice-${index}`}>
                                                                                            {parseTextType(child_element)}
                                                                                        </span>
                                                                                    ))}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {child && child.map((child_element: any, index: number) => (
                                                                                        <span key={`${child?.[0]?.props?.key}-info-${index}`}>
                                                                                            {parseTextType(child_element)}
                                                                                        </span>
                                                                                    ))}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {child?.[0]?.text.split(":").length === 1 && 
                                                                        <>
                                                                            {child && child.map((child_element: any, index: number) => (
                                                                                <span key={`${child_element?.[0]?.props?.key}-info-${index}`}>
                                                                                    {parseTextType(child_element)}
                                                                                </span>
                                                                            ))}
                                                                        </>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>  
                                        )}
                                        {type === "advice" && (
                                            <div className="flex flex-col bg-gray-200 p-2 rounded-md mb-2 mt-2">
                                                <div className="flex flex-row items-center text-lg gap-1">
                                                    <span className="text-primary">
                                                        <Icon icon="mdi:face-happy-outline" />
                                                    </span>
                                                    <span className="underline">
                                                        {translation("post.tips")}
                                                    </span>
                                                </div>
                                                <div className="ml-5">
                                                    {children_array && children_array.map((child: any, index: number) => (
                                                        <span key={`${child?.[0]?.props?.key}-advice-${index}`}>
                                                            {parseTextType(child)}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>  
                                        )}
                                        {type === "map" && (
                                            <>
                                                {children?.[1]?.props?.content?.children.map((child: any, index: number) => (
                                                    <div key={`${child?.[0]?.props?.key}-map-${index}`}>
                                                        {child.type === "link" && child?.children?.[0].text &&(
                                                            <React.Suspense fallback={<div>Loading Map...</div>}>
                                                                <GoogleMapIframe src={child?.children?.[0].text} width="100%" height="480" />
                                                            </React.Suspense>
                                                        )}
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                        {type === "advertisement" && (
                                            <>
                                                <div className="flex flex-col bg-gray-200 p-2 rounded-md mb-2 mt-2">
                                                    <div className="flex flex-row items-center text-lg gap-1">
                                                        <span className="text-primary">
                                                            <Icon icon="mdi:advertisements" />
                                                        </span>
                                                        <span className="underline">
                                                            {translation("post.advertisement")}
                                                        </span>
                                                    </div>
                                                    <div className="ml-5">
                                                        <a href={adv_link} target="_blank" rel="noreferrer" className="no-underline">
                                                            <span>{adv_title}</span>
                                                            <div className="relative pr-4 -mt-[1rem] -mb-[1rem]">
                                                                <Image
                                                                    src={adv_image}
                                                                    alt={adv_title}
                                                                    width={300}
                                                                    height={300}
                                                                    className="rounded-md drop-shadow-2xl mx-auto"
                                                                    priority={false}
                                                                    quality={30}
                                                                />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div> 
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )
                    }
                }}
            />
        </div>
    )
}

export const getStaticProps = async ({ locale }) => ({
    props: {
      ...(await serverSideTranslations(locale, ['common', 'navbar', 'post'])),
    },
})

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
    return {
        paths: [],
        fallback: 'blocking'
    }
}
